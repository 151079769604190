import { useCallback, useEffect, useRef } from 'react';
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import FeatureLayerView from '@arcgis/core/views/layers/FeatureLayerView';
import { App } from '@jll/react-ui-components';
import { get, includes, isEmpty, uniqBy } from 'lodash';

import MoveOsmHighlightPinMenu from 'components/mapContextMenu/MoveOsmHighlightPinMenu';
import {
    INDUSTRIAL_SUBMARKETS_LAYER_ID,
    OFFICE_SUBMARKETS_LAYER_ID,
} from 'constants/map.constants';
import { getDevPipelineSourceByLayerId } from 'helpers/devPipelineHelper';
import { OSM_PINS_LAYER_ID } from 'helpers/osmBuildingLayerHelper';
import { allHighlightMetadataForKey } from 'helpers/osmHighlightSetHelper';
import { queryBuildingEditsLayer } from 'helpers/polygonEditorHelper';
import { highlightAnnotation } from 'helpers/quickLayerHelper';
import { MISSING_POLYGONS_PINS_LAYER, SEARCH_PINS_LAYER } from 'helpers/searchHelper';
import { MapContextData, useMap } from 'hooks/MapProvider';
import { AppThunk } from 'store';
import {
    rejectMatch,
    selectMatch,
    selectMatchCsvInfo,
    selectOsmHighlightInMatchMode,
    startMatch,
} from 'store/csvHighlightMatchSlice';
import { selectIsSinglePropertyViewVisible } from 'store/leftPanelSlice';
import {
    isAllPropertiesChecked,
    moveCsvLayerPin,
    moveOsmHighlightPin,
    OsmLookupItem,
    selectLibraryItemsByKey,
    selectOsmHighlightSetLookup,
} from 'store/libraryItemSlice';
import {
    loadOsmMappingForOsmIds,
    OsmMatchingProgressState,
    selectMappingLookupByOsmId,
    selectMatching,
    setDevPipelineMarketSphereIds,
    setMatching,
} from 'store/marketSphereOsmMappingSlice';
import {
    CsvPinReference,
    exitMove,
    OsmPinReference,
    selectActiveMovePin,
    selectCsvPin,
    selectOsmPin,
} from 'store/movePinSlice';
import {
    cleanSelectedGraphics,
    setSelectedGraphics,
    setSelectedLayerId,
} from 'store/polygonsLayerSlice';
import {
    selectedAnnotationMode,
    setAnnotationMode,
    setHighlightedAnnotationKey,
    setSelectedAnnotation,
    setSelectedQuickLayer,
} from 'store/quickLayerSlice';
import { RightPanelKeys, setActiveRightPanel } from 'store/rightPanelSlice';
import { setSelectedResultItem } from 'store/searchSlice';
import {
    selectSelectedPropertyId,
    setSelectedPropertyId,
    setSinglePropertyView,
} from 'store/singlePropertyViewSlice';
import { selectSelectedSubmarket, setSelectedSubmarket } from 'store/submarketsSlice';
import { clearSelectedT2HBuilding, setSelectedT2HBuilding } from 'store/tableHighlightSetSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import { primaryLibraryItemKey, QuickLayerTreeItem } from 'types/Layers/LibraryLayerTreeItem';
import { AnnotationMetadata } from 'types/Layers/QuickLayerMetadata';
import config from 'utils/config';
import { getLayerView } from 'utils/esri/layerViewUtils';
import {
    addHighlightBuilding,
    removeHighlightBuilding,
    SceneLayerSource,
} from '../HighlighSets/buildingSelectionStyleHelpers';
import MatchCsvOsmHighlightMenu from '../mapContextMenu/MatchCsvOsmHighlightMenu';

export const SEARCH_RESULTS_PINS_MAP_CLICK_EVENT_KEY = 'search-result-pins-map-click';
export const DEV_PIPELINE_MAP_CLICK_EVENT_KEY = 'dev-pipeline-map-click';
export const OSM_BUILDINGS_MAP_CLICK_EVENT_KEY = 'osm-map-click';
export const BUILDING_EDITS_LAYER_MAP_CLICK_EVENT_KEY = 'buildings-edits-layer-map-click';

export interface ViewClickHandlerContext {
    view: __esri.SceneView | undefined;
    showMapContextMenu: MapContextData['showMapContextMenu'];
    app: ReturnType<typeof App.useApp>;
}

export type ViewClickHandler = (
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent,
    extra: ViewClickHandlerContext
) => boolean;

let highlightHandler: __esri.Handle;
const devPipelineLayers: SceneLayerSource[] = ['dev-pipeline', 'highlighted-dev-pipeline'];

export const useCsvHighlightMatchClickHandler = (): ((
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent
) => boolean) => {
    const { showMapContextMenu } = useMap();
    const dispatch = useAppDispatch();
    const csvHighlightMatchState = useRef<ReturnType<typeof selectMatchCsvInfo>>({
        state: 'INACTIVE',
    });
    csvHighlightMatchState.current = useAppSelector(selectMatchCsvInfo);

    const matchModeCsvHighlightId = useRef<ReturnType<typeof selectOsmHighlightInMatchMode>>();
    matchModeCsvHighlightId.current = useAppSelector(selectOsmHighlightInMatchMode);

    const libraryItemsByKey = useRef<ReturnType<typeof selectLibraryItemsByKey>>({});
    libraryItemsByKey.current = useAppSelector(selectLibraryItemsByKey);

    const osmLookup = useRef<Record<string, OsmLookupItem | undefined>>();
    osmLookup.current = useAppSelector(selectOsmHighlightSetLookup);

    return useCallback(
        (results, position) => {
            const firstResult = results.results[0];
            if (firstResult && firstResult.type === 'graphic') {
                const graphic = firstResult.graphic;
                const osmId = graphic.attributes.OSMID || graphic.attributes.osmId;
                const possibleLibraryKeyId = graphic.attributes.libraryItemKey as unknown;
                const libraryKeyId =
                    typeof possibleLibraryKeyId === 'string' ? possibleLibraryKeyId : undefined;
                const item = osmLookup.current?.[osmId];

                dispatch(clearSelectedT2HBuilding());
                if (
                    ['SELECT_MATCH', 'CONFIRM_MATCH'].includes(
                        csvHighlightMatchState.current.state
                    ) &&
                    osmId
                ) {
                    dispatch(selectMatch({ osmId: graphic.attributes.OSMID }));
                    showMapContextMenu([position.x, position.y], MatchCsvOsmHighlightMenu);
                    return true;
                } else if (firstResult.layer.id.endsWith('-unmatched-pins') && libraryKeyId) {
                    const primaryItemKey = primaryLibraryItemKey(libraryKeyId);
                    if (primaryItemKey === matchModeCsvHighlightId.current) {
                        dispatch(startMatch(String(graphic.attributes.libraryItemKey)));
                    } else if (
                        !['SELECT_MATCH', 'CONFIRM_MATCH'].includes(
                            csvHighlightMatchState.current.state
                        )
                    ) {
                        const primaryItem = libraryItemsByKey.current[primaryItemKey];
                        const { primaryMetadata, buildingMetadata } = allHighlightMetadataForKey(
                            libraryKeyId,
                            primaryItem
                        );
                        if (primaryMetadata && buildingMetadata) {
                            dispatch(
                                setSelectedT2HBuilding({
                                    csvLayerMetadata: primaryMetadata.csvLayerMetadata,
                                    building: buildingMetadata,
                                })
                            );
                        }
                    }
                    return true;
                } else if (item) {
                    if (item && item.checked && item.csvLayerMetadata != null) {
                        dispatch(
                            setSelectedT2HBuilding({
                                csvLayerMetadata: item.csvLayerMetadata,
                                building: item.building,
                            })
                        );
                        return true;
                    }
                }
            }

            if (csvHighlightMatchState.current.state === 'CONFIRM_MATCH') {
                dispatch(rejectMatch());
            }

            return false;
        },
        [showMapContextMenu, dispatch]
    );
};

function handleSPV(marketSphereId: string, osmId?: number): AppThunk<boolean> {
    return (dispatch) => {
        dispatch(setSinglePropertyView(!!marketSphereId));
        dispatch(cleanSelectedGraphics());
        if (!marketSphereId) {
            return false;
        }
        dispatch(setSelectedPropertyId(marketSphereId));
        if (osmId) {
            addHighlightBuilding('osm', osmId, OSM_BUILDINGS_MAP_CLICK_EVENT_KEY, false).then(
                () => {
                    removeHighlightBuilding(
                        [...devPipelineLayers, 'devpipeline-pins'],
                        DEV_PIPELINE_MAP_CLICK_EVENT_KEY
                    );
                }
            );
        }
        return true;
    };
}

function handleOsmClick(
    graphic: Graphic,
    event: __esri.ViewClickEvent,
    showMapContextMenu: MapContextData['showMapContextMenu']
): AppThunk<boolean> {
    return (dispatch, getState) => {
        const state = getState();
        const allPropertiesChecked = isAllPropertiesChecked(state);
        const mappingLookupByOsmId = selectMappingLookupByOsmId(state);
        const matching = selectMatching(state);
        const selectedPropertyId = selectSelectedPropertyId(state);

        const osmId = graphic?.attributes?.OSMID;
        const marketSphereId =
            mappingLookupByOsmId?.[osmId]?.marketSpherePropertyId ??
            graphic?.attributes?.marketSpherePropertyId;

        if (event.button === 2 && osmId) {
            showMapContextMenu([event.x, event.y]);
            dispatch(setSelectedGraphics([graphic]));
            return true;
        }

        const matchingState = matching?.state;
        if (
            matching !== null &&
            matchingState &&
            ['matching', 'reviewing'].includes(matchingState)
        ) {
            if (!osmId) return false;
            addHighlightBuilding('osm', osmId, OSM_BUILDINGS_MAP_CLICK_EVENT_KEY, false);
            const selectedPropertyIdAsNumber = Number(selectedPropertyId);
            if (selectedPropertyId && Number.isFinite(selectedPropertyIdAsNumber)) {
                addHighlightBuilding(
                    'missing-pins',
                    selectedPropertyIdAsNumber,
                    SEARCH_RESULTS_PINS_MAP_CLICK_EVENT_KEY,
                    false
                );
            }
            dispatch(
                setMatching({
                    osmId: osmId,
                    state: 'matching',
                })
            );
            return true;
        }

        if (marketSphereId) {
            return dispatch(handleSPV(marketSphereId, osmId));
        }

        if (!marketSphereId) {
            if (allPropertiesChecked || !osmId) return false;
            document.body.style.cursor = 'wait';
            dispatch(loadOsmMappingForOsmIds([osmId]))
                .unwrap()
                .then((mappings) => {
                    if (!mappings?.length) return false;
                    const msId = mappings[0].marketSpherePropertyId;
                    if (msId) return dispatch(handleSPV(msId.toString(), osmId));
                    else return false;
                })
                .finally(() => {
                    document.body.style.cursor = 'default';
                    return false;
                });
        }
        return !!marketSphereId;
    };
}

function handleDevPipelineClick(
    graphic: Graphic,
    event: __esri.ViewClickEvent,
    showMapContextMenu: MapContextData['showMapContextMenu']
): AppThunk<boolean> {
    return (dispatch) => {
        const marketSphereId = graphic.getAttribute('MarketSpherePropertyId');
        dispatch(setDevPipelineMarketSphereIds([marketSphereId]));
        const bbId = graphic?.getAttribute('BlackbirdId');
        if (!bbId || !marketSphereId) return false;
        const devPipelineLayerId = getDevPipelineSourceByLayerId(graphic.layer.id);
        addHighlightBuilding(devPipelineLayerId, bbId, DEV_PIPELINE_MAP_CLICK_EVENT_KEY, false);

        if (event.button === 2) {
            showMapContextMenu([event.x, event.y]);
            dispatch(setSelectedGraphics([graphic]));
            return true;
        }

        dispatch(setSinglePropertyView(true));
        dispatch(setSelectedPropertyId(marketSphereId));
        return true;
    };
}

function handleEditedBuildingClick(
    graphic: Graphic,
    event: __esri.ViewClickEvent,
    showMapContextMenu: MapContextData['showMapContextMenu']
): AppThunk<boolean> {
    return (dispatch) => {
        const marketSpherePropertyId = graphic.getAttribute('MarketSpherePropertyId');
        if (event.button === 2 && marketSpherePropertyId) {
            showMapContextMenu([event.x, event.y]);
            dispatch(setSelectedGraphics([graphic]));
            return true;
        }
        queryBuildingEditsLayer([marketSpherePropertyId]).then((features) => {
            if (!features) return;

            const uniqueFeatures = uniqBy(features, (feature) => feature.attributes['BlackbirdId']);
            dispatch(setSelectedGraphics(uniqueFeatures));

            if (event.button === 2) {
                showMapContextMenu([event.x, event.y]);
            } else if (marketSpherePropertyId) {
                dispatch(setSelectedPropertyId(marketSpherePropertyId));
                dispatch(setSinglePropertyView(true));
            }
        });

        if (marketSpherePropertyId) {
            addHighlightBuilding(
                'building-edits-layer',
                marketSpherePropertyId,
                BUILDING_EDITS_LAYER_MAP_CLICK_EVENT_KEY,
                false
            );
            return true;
        }

        return false;
    };
}

export const useBuildingsClickHandler = (): ViewClickHandler => {
    const dispatch = useAppDispatch();
    const matching = useRef<OsmMatchingProgressState | null>();
    matching.current = useAppSelector(selectMatching);
    const isMatchingActive = matching.current !== null && matching.current?.state === 'matching';
    const spvActive = useAppSelector(selectIsSinglePropertyViewVisible);

    useEffect(() => {
        if (!spvActive && !isMatchingActive) {
            dispatch(cleanSelectedGraphics());
            dispatch(setSelectedResultItem(undefined));
            removeHighlightBuilding(
                'building-edits-layer',
                BUILDING_EDITS_LAYER_MAP_CLICK_EVENT_KEY
            );
            removeHighlightBuilding('osm', OSM_BUILDINGS_MAP_CLICK_EVENT_KEY);
            removeHighlightBuilding(
                [...devPipelineLayers, 'devpipeline-pins'],
                DEV_PIPELINE_MAP_CLICK_EVENT_KEY
            );
        }
    }, [dispatch, isMatchingActive, spvActive]);

    return useCallback(
        (result, event, { showMapContextMenu }) => {
            const firstResult = result.results[0];

            if (!firstResult || firstResult.type !== 'graphic') {
                dispatch(setSinglePropertyView(false));
                return false;
            }

            removeHighlightBuilding(
                ['dev-pipeline', 'highlighted-dev-pipeline'],
                DEV_PIPELINE_MAP_CLICK_EVENT_KEY
            );
            removeHighlightBuilding(['osm'], OSM_BUILDINGS_MAP_CLICK_EVENT_KEY);
            removeHighlightBuilding(
                'building-edits-layer',
                BUILDING_EDITS_LAYER_MAP_CLICK_EVENT_KEY
            );
            dispatch(setSelectedLayerId(firstResult.layer.id));
            switch (firstResult.layer.id) {
                case 'osm-scene-layer':
                    return dispatch(handleOsmClick(firstResult.graphic, event, showMapContextMenu));
                case 'devpipeline-scene-layer2':
                case 'devpipeline-scene-layer':
                    return dispatch(
                        handleDevPipelineClick(firstResult.graphic, event, showMapContextMenu)
                    );
                case config.buildingEditsLayerItemId:
                    return dispatch(
                        handleEditedBuildingClick(firstResult.graphic, event, showMapContextMenu)
                    );
                default:
                    return false;
            }
        },
        [dispatch]
    );
};

export const usePinsClickHandler = (): ((
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent
) => boolean) => {
    const selectProperty = Number(useAppSelector(selectSelectedPropertyId));
    const dispatch = useAppDispatch();
    const spvActive = useAppSelector(selectIsSinglePropertyViewVisible);
    const highlightedProperty = useRef<number>();
    const { showMapContextMenu } = useMap();

    useEffect(() => {
        if (
            !spvActive ||
            (!isNaN(selectProperty) && selectProperty !== highlightedProperty.current)
        ) {
            removeHighlightBuilding(
                ['search-result-pins', 'missing-pins'],
                SEARCH_RESULTS_PINS_MAP_CLICK_EVENT_KEY
            );
            highlightedProperty.current = selectProperty;
        }
    }, [spvActive, selectProperty]);

    return useCallback(
        (result: __esri.SceneViewHitTestResult, event) => {
            removeHighlightBuilding(
                ['search-result-pins', 'missing-pins'],
                SEARCH_RESULTS_PINS_MAP_CLICK_EVENT_KEY
            );
            const firstResult = result.results.at(0);
            if (!firstResult || firstResult.type !== 'graphic') {
                return false;
            }
            const graphic = firstResult.graphic;
            const layerId = graphic.layer.id;
            const buildingType =
                layerId == SEARCH_PINS_LAYER
                    ? 'search-result-pins'
                    : layerId == MISSING_POLYGONS_PINS_LAYER
                    ? 'missing-pins'
                    : undefined;
            const marketSpherePropertyId = graphic?.getAttribute('marketSpherePropertyId');
            if (!marketSpherePropertyId || !buildingType) return false;

            dispatch(setSinglePropertyView(!!marketSpherePropertyId));
            dispatch(setSelectedPropertyId(marketSpherePropertyId ?? ''));
            highlightedProperty.current = marketSpherePropertyId;
            addHighlightBuilding(
                buildingType,
                marketSpherePropertyId,
                SEARCH_RESULTS_PINS_MAP_CLICK_EVENT_KEY,
                false
            );
            if (layerId === MISSING_POLYGONS_PINS_LAYER) {
                if (event.button === 2) showMapContextMenu([event.x, event.y]);
                dispatch(setSelectedGraphics([graphic]));
                return true;
            }
            return true;
        },
        [dispatch, showMapContextMenu]
    );
};

export const useSubmarketClickHandler = (): ((
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent
) => boolean) => {
    const dispatch = useAppDispatch();
    const selectedSubmarket = useAppSelector(selectSelectedSubmarket);

    useEffect(() => {
        if (!selectedSubmarket && highlightHandler) {
            highlightHandler.remove();
        }
    }, [selectedSubmarket]);

    return useCallback(
        (result, event) => {
            if (event.button !== 0) return false;
            const firstResult = result.results[0];
            if (
                !firstResult ||
                firstResult.type !== 'graphic' ||
                !includes(
                    [OFFICE_SUBMARKETS_LAYER_ID, INDUSTRIAL_SUBMARKETS_LAYER_ID],
                    firstResult.layer.id
                )
            ) {
                return false;
            }

            const graphic = firstResult.graphic;
            const marketCode = graphic.getAttribute('MarketCode');
            const propertyType = graphic.getAttribute('PropertyType');
            const submarketCode = graphic.getAttribute('SubmarketCode');
            const submarket = graphic.getAttribute('Submarket');

            if (!marketCode || !submarketCode || !propertyType) return false;
            dispatch(setSelectedSubmarket({ marketCode, propertyType, submarket, submarketCode }));
            dispatch(setActiveRightPanel(RightPanelKeys.SubmarketStatistics));

            if (graphic.layer) {
                getLayerView(graphic.layer).then((layerView) => {
                    if (layerView) {
                        if (highlightHandler) highlightHandler.remove();
                        highlightHandler = (layerView as FeatureLayerView).highlight(graphic);
                    }
                });
            }

            return true;
        },
        [dispatch]
    );
};

export const useQuickLayerClickHandler = (): ((
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent
) => boolean) => {
    const dispatch = useAppDispatch();
    const annotationMode = useAppSelector(selectedAnnotationMode);
    const libraryItemsByKey = useRef<ReturnType<typeof selectLibraryItemsByKey>>({});
    libraryItemsByKey.current = useAppSelector(selectLibraryItemsByKey);

    return useCallback(
        (result, event) => {
            if (!isEmpty(result?.results) && result?.results[0]?.type === 'graphic') {
                const graphic = result?.results[0]?.graphic;
                if (event.button !== 2 && graphic) {
                    const graphicKey = get(graphic, 'key') ?? graphic?.getAttribute?.('key');

                    if (!graphicKey) return false;

                    highlightHandler?.remove();

                    const libraryItem = libraryItemsByKey.current[graphicKey] as QuickLayerTreeItem;
                    if (!libraryItem) return false;

                    const style = (libraryItem.metadata as AnnotationMetadata)?.style;

                    const layer = graphic.layer as FeatureLayer;
                    if (!layer) return false;

                    layer
                        .queryFeatures({
                            where: `key = '${graphicKey}'`,
                            returnGeometry: true,
                        })
                        .then((features) => {
                            if (!features?.features?.length) return;
                            const feature = features.features[0];

                            feature.set('key', graphicKey);
                            feature.set('style', style);
                            dispatch(setSelectedAnnotation(feature));

                            const parentItem =
                                libraryItem.ownerId &&
                                (libraryItemsByKey.current[
                                    libraryItem.ownerId
                                ] as QuickLayerTreeItem);
                            if (parentItem) dispatch(setSelectedQuickLayer(parentItem));

                            if (annotationMode !== 'edit') {
                                dispatch(setAnnotationMode('view'));
                            }

                            dispatch(setActiveRightPanel(RightPanelKeys.QuickLayer));

                            dispatch(setHighlightedAnnotationKey(libraryItem.key));

                            highlightAnnotation(feature, layer).then((handler) => {
                                highlightHandler = handler;
                            });
                            return true;
                        });
                }
            }

            return false;
        },
        [annotationMode, dispatch]
    );
};

function closestHitTestResult(
    result: __esri.SceneViewHitTestResult | undefined
): Point | undefined {
    if (!result) {
        return undefined;
    }

    // Prefer feature matches over ground matches.
    // The results array is sorted by distance,
    // so we can use the first one that has a location.
    const closestFeatureResult = result.results.find((result) => result.mapPoint);
    if (closestFeatureResult) {
        return closestFeatureResult.mapPoint;
    }

    // Fallback to ground mapPoint if available
    return result.ground.mapPoint ?? undefined;
}

function moveOsmPin(
    result: __esri.SceneViewHitTestResult,
    pinReference: OsmPinReference,
    messageReference: string,
    { view, app: { message } }: ViewClickHandlerContext
): AppThunk<void> {
    return async (dispatch) => {
        if (!view) return;

        try {
            const coordinates = closestHitTestResult(result);
            const layer = view.map.findLayerById(pinReference.layerId) as FeatureLayer;
            const selectedGraphicSet = await layer.queryFeatures({
                objectIds: [pinReference.objectId],
            });
            const selectedGraphic = selectedGraphicSet.features[0];

            if (!coordinates || !selectedGraphic) {
                message.destroy(messageReference);
                message.warning('Pin move cancelled');
                return;
            }

            const updatedGeometry = new Point({
                x: coordinates.x,
                y: coordinates.y,
                spatialReference: coordinates.spatialReference,
            });

            selectedGraphic.geometry = updatedGeometry;

            const pinsLayer = view?.map.findLayerById(OSM_PINS_LAYER_ID);
            if (!(pinsLayer instanceof FeatureLayer)) {
                throw TypeError(
                    'The `OSM_PINS_LAYER_ID` layer is not the expected type: FeatureLayer'
                );
            }

            const edits = {
                updateFeatures: [selectedGraphic],
            };

            await pinsLayer.applyEdits(edits);

            dispatch(
                moveOsmHighlightPin({
                    key: selectedGraphic.attributes.highlightItemKey,
                    osmId: selectedGraphic.attributes.osmId,
                    position: {
                        longitude: updatedGeometry.longitude,
                        latitude: updatedGeometry.latitude,
                    },
                })
            );

            message.destroy(messageReference);
            message.success(
                `Pin from Highlight set entry ${pinReference.osmId} successfully moved`
            );
        } catch (e) {
            message.destroy(messageReference);
            message.error('An error occurred moving the pin');
            throw e;
        } finally {
            dispatch(exitMove());
        }
    };
}

function moveCsvPin(
    result: __esri.SceneViewHitTestResult,
    pinReference: CsvPinReference,
    messageReference: string,
    { view, app: { message } }: ViewClickHandlerContext
): AppThunk<void> {
    return async (dispatch) => {
        if (!view) return;

        try {
            const coordinates = closestHitTestResult(result);

            if (!coordinates) {
                message.destroy(messageReference);
                message.warning('Pin move cancelled');
                return;
            }

            dispatch(
                moveCsvLayerPin({
                    key: pinReference.csvLayerTreeItemKey,
                    index: pinReference.index,
                    position: {
                        longitude: coordinates.longitude,
                        latitude: coordinates.latitude,
                    },
                })
            );

            message.destroy(messageReference);
            message.success(
                `Pin from Spreadsheet Layer entry ${pinReference.index} successfully moved`
            );
        } catch (e) {
            message.destroy(messageReference);
            message.error('An error occurred moving the pin');
            throw e;
        } finally {
            dispatch(exitMove());
        }
    };
}

function handleMovePinClick(
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent,
    context: ViewClickHandlerContext
): AppThunk<boolean> {
    return (dispatch, getState) => {
        const state = getState();
        const activeMovePin = selectActiveMovePin(state);
        const itemsByKey = selectLibraryItemsByKey(state);

        if (!activeMovePin) {
            if (event.button !== 2) {
                return false;
            }

            const graphic =
                result?.results?.[0]?.type === 'graphic' ? result.results[0].graphic : null;

            if (graphic?.layer?.id === OSM_PINS_LAYER_ID) {
                dispatch(
                    selectOsmPin({
                        key: graphic.attributes.highlightItemKey,
                        osmId: graphic.attributes.osmId,
                        layerId: graphic.layer.id,
                        objectId: graphic.getObjectId(),
                    })
                );
                context.showMapContextMenu([event.x, event.y], MoveOsmHighlightPinMenu);
                return true;
            } else {
                const item = graphic?.layer.id ? itemsByKey[graphic?.layer.id] : undefined;

                if (graphic && item?.itemType === 'CsvLayer') {
                    dispatch(
                        selectCsvPin({
                            csvLayerTreeItemKey: item.key,
                            index: graphic.getObjectId() - 1,
                        })
                    );
                    context.showMapContextMenu([event.x, event.y], MoveOsmHighlightPinMenu);
                    return true;
                }
            }
            return false;
        }

        switch (activeMovePin.pinReference.type) {
            case 'osm-pin-reference':
                dispatch(
                    moveOsmPin(
                        result,
                        activeMovePin.pinReference,
                        activeMovePin.messageReference,
                        context
                    )
                );
                return true;
            case 'csv-pin-reference':
                dispatch(
                    moveCsvPin(
                        result,
                        activeMovePin.pinReference,
                        activeMovePin.messageReference,
                        context
                    )
                );
                return true;
        }
    };
}

export function useMovePinClickHandler(): ViewClickHandler {
    const dispatch = useAppDispatch();

    return useCallback(
        (result, event, extra): boolean => dispatch(handleMovePinClick(result, event, extra)),
        [dispatch]
    );
}
