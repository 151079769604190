import { gql } from '@apollo/client';

import CspDigitalAsset from 'types/CspDigitalAsset';

export const SEARCH_SPV_AVAILABILITIES = gql`
    query SearchAvailabilities(
        $bronzeSourceSystem: AvailabilityBronzeSourceSystem!
        $input: AvailabilitySearchParametersInput!
    ) {
        searchAvailabilities(bronzeSourceSystem: $bronzeSourceSystem, input: $input) {
            availabilities {
                id
                property {
                    id
                }
                suite
                isFullFloor
                isAvailable
                allFloors
                areaMax
                areaMaxUoM
                askingRentMax
                askingRentMin
                rentUoM
                rentType
                leaseType
                dateOnMarket
                availabilityExpiryDate
                rentAmountAverage
            }
        }
    }
`;

export const SEARCH_AVAILABILITIES = gql`
    query SearchAvailabilities(
        $bronzeSourceSystem: AvailabilityBronzeSourceSystem!
        $input: AvailabilitySearchParametersInput!
    ) {
        searchAvailabilities(bronzeSourceSystem: $bronzeSourceSystem, input: $input) {
            availabilities {
                property {
                    id
                    addresses {
                        addressLine1
                        latitude
                        longitude
                    }
                    buildingStatus
                    businessParkOrComplexName
                    name
                    marketHierarchy {
                        marketLevel2
                    }
                    sourcePropertyNumber
                }
                suite
                allFloors
                areaMax
                areaMaxStandard
                availabilityId
                rentAmountAverage
                askingRentMax
                askingRentMin
                rentUoM
                rentType
                leaseType
                sourceMetadata {
                    sourceSystem
                }
            }
            stats {
                totalProperties
                geoBoundingBox {
                    topLeft {
                        lat
                        long
                    }
                    bottomRight {
                        lat
                        long
                    }
                }
            }
        }
    }
`;

export const SEARCH_AVAILABILITIES_IDS = gql`
    query SearchAvailabilities(
        $bronzeSourceSystem: AvailabilityBronzeSourceSystem!
        $input: AvailabilitySearchParametersInput!
    ) {
        searchAvailabilities(bronzeSourceSystem: $bronzeSourceSystem, input: $input) {
            availabilities {
                availabilityId
                property {
                    id
                    addresses {
                        latitude
                        longitude
                    }
                }
            }
            stats {
                totalProperties
                geoBoundingBox {
                    topLeft {
                        lat
                        long
                    }
                    bottomRight {
                        lat
                        long
                    }
                }
            }
        }
    }
`;

export const GET_SUBMARKET_STATISTICS = gql`
    query SearchProperties($input: PropertySearchParametersInput!) {
        searchProperties(input: $input) {
            properties {
                marketHierarchy {
                    marketLevel2
                }
                area {
                    totalAvailableSpace {
                        value
                    }
                }
            }
        }
    }
`;

export const PROPERTY_DIGITAL_ASSETS = gql`
    fragment PropertyDigitalAssets on Property {
        digitalAssets {
            isPrimary
            assetLocation
            assetTitle
            digitalAssetType
            displayOrderNumber
        }
    }
`;

export const PROPERTY_ADDRESS = gql`
    fragment PropertyAddress on Address {
        address {
            line1
        }
    }
`;

export const PROPERTY_LOCATION = gql`
    fragment PropertyLocation on Address {
        geolocation {
            latitude
            longitude
        }
    }
`;

export const GET_PROPERTY_DIGITAL_ASSETS = gql`
    query GetProperty($propertyId: String!, $source: String) {
        getProperty(id: $propertyId, source: $source) {
            digitalAssets {
                digitalAssetType
                digitalAssetSubType
                fileName
                assetTitle
                assetDescription
                assetLocation
                fileSubType
                fileType
                isPrimary
                isHidden
                isBrochure
            }
        }
    }
`;

export const GET_PROPERTY_ID = gql`
    query GetProperty($propertyId: String!, $source: String) {
        getProperty(id: $propertyId, source: $source) {
            id
            propertyId
            name
            address {
                address {
                    line1
                }
            }
        }
    }
`;

export const GET_PROPERTY = gql`
    query GetBronzeProperty($propertyId: String!, $source: String) {
        getBronzeProperty(id: $propertyId, source: $source) {
            id
            propertyId
            name
            sourceMetadata {
                sourcePropertyNumber
                sourceSystem
            }
            address {
                address {
                    line1
                }
                geolocation {
                    latitude
                    longitude
                }
            }
            markets {
                level2
            }
            propertyType
            propertyStatus
            construction {
                propertyGrade
                buildTime {
                    year
                }
            }
            floors {
                count {
                    aboveGround
                }
            }
            dimensions {
                area {
                    total {
                        area {
                            value
                            UoM
                        }
                    }
                    buildingContigSpaceAvailableMax {
                        value
                    }
                }
            }
            companies {
                name
                role
                isPrimary
            }
            infrastructure {
                docks {
                    total
                }
                clearanceHeight {
                    minimum
                    maximum
                }
            }
            description {
                currentOccupancyPercentage
            }
            digitalAssets {
                isPrimary
                assetLocation
                assetTitle
                digitalAssetType
                displayOrderNumber
            }
        }
    }
`;

export const SEARCH_SPV_SALES = gql`
    query SearchSalesTransaction(
        $silverSourceSystem: SalesSourceSystem!
        $input: SalesTransactionSearchParametersInput!
    ) {
        searchSalesTransaction(silverSourceSystem: $silverSourceSystem, input: $input) {
            salesTransactions {
                id
                closeDate
                salePrice
                pricePerArea
                buyer
                seller
                yieldCapRate
            }
        }
    }
`;

export const SEARCH_SPV_LEASES = gql`
    query SearchLeases(
        $silverSourceSystem: LeaseSourceSystem!
        $input: LeaseSearchParametersInput!
    ) {
        searchLeases(silverSourceSystem: $silverSourceSystem, input: $input) {
            leases {
                leaseId
                leaseSpaces {
                    areaLeased
                    floorText
                }
                companies {
                    name
                    role
                    companyLogoPath
                }
                leaseRoot {
                    leaseType
                    totalArea
                }
                financialTerm {
                    leaseTerm
                    firstYearRentAmount
                    rentalType
                    freeRentMonthCount
                    rentEscalation {
                        frequency
                        escalationType
                        escalationAmount
                    }
                    landlordImprovementAllowance {
                        amount
                    }
                }
                transactionDate {
                    commencementDate
                    expirationDate
                    executionDate
                }
            }
        }
    }
`;

export const SEARCH_SALES = gql`
    query SearchSalesTransaction(
        $silverSourceSystem: SalesSourceSystem!
        $input: SalesTransactionSearchParametersInput!
    ) {
        searchSalesTransaction(silverSourceSystem: $silverSourceSystem, input: $input) {
            salesTransactions {
                transactionId
                closeDate
                salePrice
                pricePerArea
                buyer
                seller
                property {
                    id
                    name
                    address {
                        addressLine1
                        geolocation {
                            latitude
                            longitude
                        }
                    }
                    buildingStatus
                    businessParkOrComplexName
                    name
                    marketHierarchy {
                        marketLevel2
                    }
                }
                sourceMetadatas {
                    sourceSystem
                }
            }
            stats {
                totalSales
                geoBoundingBox {
                    topLeft {
                        lat
                        long
                    }
                    bottomRight {
                        lat
                        long
                    }
                }
            }
        }
    }
`;

export const SEARCH_LEASE = gql`
    query SearchLeases(
        $silverSourceSystem: LeaseSourceSystem!
        $input: LeaseSearchParametersInput!
    ) {
        searchLeases(silverSourceSystem: $silverSourceSystem, input: $input) {
            leases {
                leaseId
                companies {
                    name
                    role
                }
                leaseRoot {
                    totalArea
                }
                financialTerm {
                    leaseTerm
                }
                transactionDate {
                    executionDate
                }
                property {
                    id
                    addresses {
                        addressLine1
                        latitude
                        longitude
                    }
                    propertyName
                    marketHierarchy {
                        marketLevel2
                    }
                }
                sourceMetadata {
                    sourceSystem
                }
            }
            stats {
                geoBoundingBox {
                    topLeft {
                        lat
                        long
                    }
                    bottomRight {
                        lat
                        long
                    }
                }
            }
        }
    }
`;

export const SEARCH_LEASE_IDS = gql`
    query SearchLeases(
        $silverSourceSystem: LeaseSourceSystem!
        $input: LeaseSearchParametersInput!
    ) {
        searchLeases(silverSourceSystem: $silverSourceSystem, input: $input) {
            leases {
                leaseId
                property {
                    id
                }
            }
        }
    }
`;

export interface PropertyGraphQLResult {
    getProperty: CspProperty;
}

export interface BronzePropertyGraphQLResult {
    getBronzeProperty: CspProperty;
}

export interface AvailabilitiesSpvGraphQLResult {
    searchAvailabilities: {
        availabilities: CspSpvAvailability[];
    };
}

export interface SalesSpvGraphQLResult {
    searchSalesTransaction: {
        salesTransactions: CspSpvSales[];
    };
}

export interface LeaseSpvGraphQLResult {
    searchLeases: {
        leases: CspSpvLeases[];
    };
}

export interface CspSpvSales {
    id: string;
    closeDate: string;
    salePrice: number;
    pricePerArea: number;
    buyer: string;
    seller: string;
    yieldCapRate: number;
}

export interface CspSpvLeases {
    leaseId: string;
    leaseSpaces:
        | Array<{
              areaLeased: number;
              floorText: string;
          }>
        | undefined;
    companies: {
        name: string;
        role: string;
        companyLogoPath: string;
    }[];
    leaseRoot: {
        leaseType: string;
        totalArea: number;
    };
    financialTerm: {
        leaseTerm: string;
        firstYearRentAmount: number;
        rentalType: string;
        freeRentMonthCount: number;
        rentEscalation: {
            escalationType: string;
            escalationAmount: number;
        };
        landlordImprovementAllowance: {
            amount: number;
        };
    };
    transactionDate: {
        commencementDate: string;
        expirationDate: string;
        executionDate: string;
    };
}

export interface CspSpvAvailability {
    id: string;
    property: {
        id: string;
    };
    suite?: string;
    isAvailable?: boolean;
    isFullFloor?: boolean;
    allFloors?: string;
    areaMax?: number;
    askingRentMax?: number;
    askingRentMin?: number;
    rentType?: string;
    leaseType?: string;
    dateOnMarket?: string;
    availabilityExpiryDate?: string;
}

export interface Infrastructure {
    docks: {
        total?: number;
    };
    clearanceHeight: {
        minimum?: number;
        maximum?: number;
    };
}

export interface CspCompany {
    name?: string;
    role?: string;
    isPrimary?: boolean;
}

interface CspSourceMetadata {
    sourcePropertyNumber: string;
    sourceSystem: string;
}

export interface PropertyStats {
    totalProperties?: number;
    geoBoundingBox?: {
        topLeft: {
            lat: number;
            long: number;
        };
        bottomRight: {
            lat: number;
            long: number;
        };
    };
}

export interface CspProperty {
    id?: string;
    propertyId?: string;
    coreIds?: string[];
    name?: string;
    sourceMetadata?: CspSourceMetadata[];
    address?: Array<{
        address?: {
            line1?: string;
        };
        geolocation?: {
            latitude?: number;
            longitude?: number;
        };
    }>;
    markets?: {
        level2?: string;
    };
    propertyType?: string;
    propertyStatus?: string;
    construction?: {
        propertyGrade?: string;
        buildTime?: {
            year?: number;
        };
        lastRenovationDate?: string;
    };
    floors?: {
        count?: {
            aboveGround?: number;
        };
    };
    dimensions?: {
        area?: {
            total?: {
                area?: {
                    value?: number;
                    UoM?: string;
                };
            };
            buildingContigSpaceAvailableMax?: {
                value?: number;
            };
        };
    };
    companies?: CspCompany[];
    digitalAssets?: CspDigitalAsset[];
    infrastructure?: Infrastructure;
    description?: {
        currentOccupancyPercentage?: number;
    };
}

export const GET_PROPERTY_ADDRESS = gql`
    query GetBronzeProperty($id: String!) {
        getBronzeProperty(id: $id) {
            name
            address {
                address {
                    line1
                }
            }
            propertyType
            propertyStatus
        }
    }
`;

export const GET_MARKET_BOUNDS = gql`
    query SearchProperties(
        $bronzeSourceSystem: PropertyBronzeSourceSystem
        $input: PropertySearchParametersInput
    ) {
        searchProperties(bronzeSourceSystem: $bronzeSourceSystem, input: $input) {
            stats {
                geoBoundingBox {
                    topLeft {
                        lat
                        long
                    }
                    bottomRight {
                        lat
                        long
                    }
                }
            }
        }
    }
`;

export const GET_MARKET_STATS = gql`
    query SearchProperties(
        $bronzeSourceSystem: PropertyBronzeSourceSystem
        $input: PropertySearchParametersInput
    ) {
        searchProperties(bronzeSourceSystem: $bronzeSourceSystem, input: $input) {
            properties {
                companies {
                    role
                    name
                }
            }
            stats {
                yearBuilt {
                    min
                    max
                }
                totalAvailableSpace {
                    min
                    max
                }
                areaTotal {
                    min
                    max
                }
                totalPropertyStatus {
                    value
                }
                totalMarketLevel2 {
                    value
                }
                areaTotal {
                    min
                    max
                }
                totalUsageSubType {
                    count
                    value
                }
                totalCompanyName {
                    count
                    value
                }
            }
        }
    }
`;

export const GET_AVAILABILITY_STATS = gql`
    query SearchAvailabilities(
        $bronzeSourceSystem: AvailabilityBronzeSourceSystem
        $input: AvailabilitySearchParametersInput
    ) {
        searchAvailabilities(bronzeSourceSystem: $bronzeSourceSystem, input: $input) {
            stats {
                totalMarketLevel2 {
                    value
                }
                totalBuildingStatus {
                    value
                }
                buildingContigSpace {
                    min
                    max
                }
                areaMax {
                    min
                    max
                }
                totalAvailabilities
                totalBuildingClass {
                    value
                }
                totalSubmarketCluster {
                    value
                }
                askingRentMax {
                    min
                    max
                }
            }
        }
    }
`;

export const SEARCH_PROPERTIES = gql`
    query SearchProperties(
        $bronzeSourceSystem: PropertyBronzeSourceSystem!
        $input: PropertySearchParametersInput!
    ) {
        searchProperties(bronzeSourceSystem: $bronzeSourceSystem, input: $input) {
            properties {
                addresses {
                    addressLine1
                    geolocation {
                        latitude
                        longitude
                    }
                }
                id
                propertyId
                name
                propertyStatus
                construction {
                    buildingClass
                }
                area {
                    totalArea {
                        value
                    }
                }
                sourceMetadatas {
                    sourceSystem
                    sourcePropertyNumber
                }
                digitalAssets {
                    assetLocation
                    digitalAssetType
                    digitalAssetTypeSourceValue
                    isPrimary
                }
                floors {
                    aboveGroundFloorCount
                }
            }
            stats {
                totalProperties
                geoBoundingBox {
                    topLeft {
                        lat
                        long
                    }
                    bottomRight {
                        lat
                        long
                    }
                }
            }
        }
    }
`;

export const SEARCH_PROPERTIES_IDS = gql`
    query SearchProperties(
        $bronzeSourceSystem: PropertyBronzeSourceSystem!
        $input: PropertySearchParametersInput!
    ) {
        searchProperties(bronzeSourceSystem: $bronzeSourceSystem, input: $input) {
            properties {
                id
                propertyId
                name
                addresses {
                    geolocation {
                        latitude
                        longitude
                    }
                }
                sourceMetadatas {
                    sourceSystem
                    sourcePropertyNumber
                }
            }
            stats {
                totalProperties
                geoBoundingBox {
                    topLeft {
                        lat
                        long
                    }
                    bottomRight {
                        lat
                        long
                    }
                }
            }
        }
    }
`;

export const GET_MISSING_PROPERTIES = gql`
    query SearchProperties(
        $bronzeSourceSystem: PropertyBronzeSourceSystem!
        $input: PropertySearchParametersInput!
    ) {
        searchProperties(bronzeSourceSystem: $bronzeSourceSystem, input: $input) {
            properties {
                id
                propertyId
                addresses {
                    geolocation {
                        latitude
                        longitude
                    }
                }
                sourceMetadatas {
                    sourceSystem
                    sourcePropertyNumber
                }
            }
        }
    }
`;

export const GET_MARKET_VIEW_VALUES = gql`
    query SearchProperties(
        $bronzeSourceSystem: PropertyBronzeSourceSystem
        $input: PropertySearchParametersInput
    ) {
        searchProperties(bronzeSourceSystem: $bronzeSourceSystem, input: $input) {
            properties {
                id
                propertyId
                name
                propertyType
                propertyStatus
                construction {
                    buildingClass
                    yearBuilt
                }
                area {
                    totalArea {
                        value
                    }
                }
                sourceMetadatas {
                    sourceSystem
                    sourcePropertyNumber
                }
            }
            stats {
                totalProperties
                geoBoundingBox {
                    topLeft {
                        lat
                        long
                    }
                    bottomRight {
                        lat
                        long
                    }
                }
            }
        }
    }
`;

export const GET_AVAILABILITIES_FLOORS = gql`
    query SearchAvailabilities(
        $bronzeSourceSystem: AvailabilityBronzeSourceSystem
        $input: AvailabilitySearchParametersInput
    ) {
        searchAvailabilities(bronzeSourceSystem: $bronzeSourceSystem, input: $input) {
            stats {
                totalAvailabilities
            }
            availabilities {
                property {
                    id
                }
                allFloors
            }
        }
    }
`;

export const GET_ALL_PROPERTIES = gql`
    query SearchProperties(
        $bronzeSourceSystem: PropertyBronzeSourceSystem!
        $input: PropertySearchParametersInput!
    ) {
        searchProperties(bronzeSourceSystem: $bronzeSourceSystem, input: $input) {
            properties {
                id
                name
                addresses {
                    addressLine1
                    geolocation {
                        latitude
                        longitude
                    }
                }
            }
        }
    }
`;

export const GET_COUNTRIES = gql`
    query {
        getCountries {
            isoName
            iso2
        }
    }
`;

export const GET_MARKETS = gql`
    query fetchMarkets {
        markets: getMarkets(country: "US")
    }
`;

export const GET_SUBMARKETS = gql`
    query fetchMarkets($market: String) {
        markets: getMarkets(country: "US", markets: { market1: $market })
    }
`;

export const GET_SEARCH_FILTER_LISTS = gql`
    query fetchLists {
        propertyGrade: getPicklistOptions(picklistName: "propertyGrade", country: "US")
        spaceUsageType: getPicklistOptions(picklistName: "spaceUsageType", country: "US")
    }
`;

export const GET_SALES_STATS = gql`
    query SearchSalesTransaction(
        $silverSourceSystem: SalesSourceSystem!
        $input: SalesTransactionSearchParametersInput!
    ) {
        searchSalesTransaction(silverSourceSystem: $silverSourceSystem, input: $input) {
            stats {
                totalPricePerArea {
                    min
                    max
                }
                totalAssetSalePrice
                totalBuyer {
                    count
                    value
                }
                totalSeller {
                    count
                    value
                }
            }
        }
    }
`;

export const GET_LEASE_STATS = gql`
    query SearchLeases(
        $silverSourceSystem: LeaseSourceSystem!
        $input: LeaseSearchParametersInput!
    ) {
        searchLeases(silverSourceSystem: $silverSourceSystem, input: $input) {
            stats {
                totalArea {
                    min
                    max
                }
                totalAskingRent {
                    min
                    max
                }
                totalIndustryCluster {
                    count
                    value
                }
            }
        }
    }
`;

export const GET_TENANTS = gql`
    query SearchLeases(
        $silverSourceSystem: LeaseSourceSystem!
        $input: LeaseSearchParametersInput!
    ) {
        searchLeases(silverSourceSystem: $silverSourceSystem, input: $input) {
            leases {
                companies {
                    role
                    name
                }
            }
        }
    }
`;
